// Disables the indexeddb write behavior to make testing less painful.
let __disableIdb = false;

/**
 * Re-enables indexeddb for testing this file
 */
export const __TEST_ONLY__REENABLE_IDB = () => {
  __disableIdb = false;
};

/**
 * Disables the IndexedDB integration. Only for use in tests!
 */
export const __TEST_ONLY__DISABLE_IDB = () => {
  __disableIdb = true;
};
let __db = null;
const REQUEST_OBJECT_STORE = 'requestCache';
const METRICS_OBJECT_STORE = 'metrics';
const open = () => {
  return new Promise((resolve, reject) => {
    try {
      const openRequest = window.indexedDB.open('framework-data-schema-resolvers/persistentCache', 2);
      if (openRequest) {
        openRequest.onerror = reject;

        // @ts-expect-error This is typed as a generic event, but it will have a `result` field
        openRequest.onupgradeneeded = ({
          target: {
            result
          }
        }) => {
          if (!result.objectStoreNames.contains(REQUEST_OBJECT_STORE)) {
            result.createObjectStore(REQUEST_OBJECT_STORE);
          }
          if (!result.objectStoreNames.contains(METRICS_OBJECT_STORE)) {
            result.createObjectStore(METRICS_OBJECT_STORE);
          }
        };

        // @ts-expect-error This is typed as a generic event, but it will have a `result` field
        openRequest.onsuccess = ({
          target: {
            result
          }
        }) => {
          // Set a global error handler on the db
          result.onerror = console.error;
          resolve(result);
        };
      }
    } catch (e) {
      reject(e);
    }
  });
};
const openIndexedDBConnection = () => {
  if (!__db) {
    __db = open();
  }
  return __db;
};

/**
 *
 * @param key The key to query in indexeddb
 * @returns A promise that resolves to a value, or rejects if the value is null/undefined or some other error ocurred
 */
export const getFromIDB = (key, store = REQUEST_OBJECT_STORE) => __disableIdb ? Promise.reject(Error('IndexedDB is disabled')) : openIndexedDBConnection().then(db => new Promise((resolve, reject) => {
  try {
    const request = db.transaction(store, 'readonly').objectStore(store).get(key);

    // @ts-expect-error This is typed as a generic event, but it will have a `result` field
    request.onsuccess = ({
      target: {
        result
      }
    }) =>
    // NOTE: Load-bearing != (not !==)
    result != null ? resolve(result) : reject(Error('Result was empty'));
    request.onerror = reject;
  } catch (e) {
    reject(e);
  }
}));
export const setInIDB = (key, value, store = REQUEST_OBJECT_STORE) => __disableIdb ? Promise.reject(Error('IndexedDB is disabled')) : openIndexedDBConnection().then(db => new Promise((resolve, reject) => {
  try {
    const request = db.transaction(store, 'readwrite').objectStore(store).put(value, key);
    request.onsuccess = resolve;
    request.onerror = reject;
  } catch (e) {
    reject(e);
  }
}));
export const clearIDB = (store = REQUEST_OBJECT_STORE) => openIndexedDBConnection().then(db => new Promise((resolve, reject) => {
  const request = db.transaction(store, 'readwrite').objectStore(store).clear();
  request.onsuccess = resolve;
  request.onerror = reject;
}));