import { getFromIDB } from './persistence/indexeddb';
import { getGlobal, hasGlobal, setGlobal } from './persistence/global';
const configKey = 'quickLoadConfig';
const getConfig = () => {
  const defaultConfig = {
    enableEarlyCacheReturn: false,
    quickLoadedRequests: new Map()
  };
  if (!hasGlobal(configKey)) {
    setGlobal(configKey, defaultConfig);
  }
  return getGlobal(configKey);
};
export const resetConfig = () => {
  const config = getConfig();
  config.enableEarlyCacheReturn = false;
  config.quickLoadedRequests = new Map();
};
export const enableEarlyCacheReturn = () => getConfig().enableEarlyCacheReturn = true;
export const disableEarlyCacheReturn = () => getConfig().enableEarlyCacheReturn = false;
export const isEarlyCacheReturnEnabled = () => getConfig().enableEarlyCacheReturn;
export const makeQuickLoadRequest = (requestName, load) => {
  getConfig().quickLoadedRequests.set(requestName, load);
};
export const hasQuickLoadRequest = requestName => getConfig().quickLoadedRequests.has(requestName);
export const getQuickLoadRequest = requestName => getConfig().quickLoadedRequests.get(requestName);
export const removeQuickLoadRequest = key => getConfig().quickLoadedRequests.delete(key);
export const doQuickLoad = requestName => {
  var _getQuickLoadRequest;
  if (!hasQuickLoadRequest(requestName)) {
    makeQuickLoadRequest(requestName, getFromIDB(requestName, 'requestCache'));
  }
  (_getQuickLoadRequest = getQuickLoadRequest(requestName)) === null || _getQuickLoadRequest === void 0 || _getQuickLoadRequest.catch(() => {
    // do nothing, this just makes our unhandled promise rejection tooling happy
    // in tests. If the failure is relevant, it will be consumed and handled in
    // withQuickLoad, otherwise we should ignore it.
  });
};
export const withQuickLoad = ({
  requestName,
  baseLoad
}) => {
  const quickLoadRequest = getQuickLoadRequest(requestName);
  if (!quickLoadRequest) {
    return baseLoad();
  }
  return quickLoadRequest.then(result => {
    removeQuickLoadRequest(requestName);
    return result;
  }).catch(() => {
    removeQuickLoadRequest(requestName);
    return baseLoad();
  });
};