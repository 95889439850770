import quickFetch from 'quick-fetch';
import { makeGqlEarlyRequest } from 'apollo-link-hub-http/quickFetch';
import { getFrameworkDataQuickFetchClient } from 'framework-data-schema-quick-fetch';
function quickListingLibAppSettings() {
  getFrameworkDataQuickFetchClient().then(client => {
    client.graph.frameworkTypes.getObjects({
      appSettingNames: ['frameworkListingLib']
    });
  }).catch(error => {
    console.error(error);
  });
}
function quickFetchGraphQL(objectTypeId) {
  makeGqlEarlyRequest({
    url: quickFetch.getApiUrl('/graphql/crm'),
    operation: {
      operationName: 'ListingLibQuery',
      // @ts-expect-error defined in webpack
      query: importGql('ListingLibQuery'),
      variables: {
        objectTypeId
      }
    }
  });
}
export function quickFetchListingLib(objectTypeId) {
  quickListingLibAppSettings();
  quickFetchGraphQL(objectTypeId);
}
export function quickFetchListingLibNoCrm() {
  quickListingLibAppSettings();
}