export const VISIBLE_TO = 'visibleTo';
export const CUSTOMIZABLE_COLUMNS = ['bounce', 'bouncerate', 'hardbounced', 'hardbouncerate', 'softbounced', 'softbouncerate', 'click', 'clickrate', 'clickthroughrate', 'created', 'delivered', 'deliveredrate', 'open', 'openrate', 'publishDate', 'reply', 'replyrate', 'sent', 'spamreport', 'spamreportrate', 'unsubscribed', 'unsubscribedrate', 'updated', 'subjectline', VISIBLE_TO];
export const COLUMN_COUNTER_KEYS = ['bounce', 'hardbounced', 'softbounced', 'click', 'open', 'sent', 'reply', 'spamreport', 'unsubscribed', 'delivered'];
export const DATE_COLUMN_KEYS = ['created', 'updated', 'publishDate'];
export const COLUMN_TO_RATIO_KEY = {
  bouncerate: 'bounceratio',
  hardbouncerate: 'hardbounceratio',
  softbouncerate: 'softbounceratio',
  clickrate: 'clickratio',
  clickthroughrate: 'clickthroughratio',
  deliveredrate: 'deliveredratio',
  openrate: 'openratio',
  replyrate: 'replyratio',
  spamreportrate: 'spamreportratio',
  unsubscribedrate: 'unsubscribedratio'
};
export const FOLDER_CREATE_ERRORS = {
  FOLDER_CATEGORY_LIMIT: 'FOLDER_CATEGORY_LIMIT'
};
export const ALL = 'all';
export const ARCHIVED = 'archived';
export const DRAFT = 'draft';
export const FILTER = 'filter';
export const SEARCH = 'search';
export const SCHEDULED = 'scheduled';
export const SENT = 'sent';
export const AI_GENERATED = 'ai';
export const CREATE_AN_EMAIL_STATE = 'createAnEmail';
export const PUBLISHED = 'published';
export const NO_MATCH_SEARCH = 'noMatchSearch';
export const NO_MATCH_FILTER = 'noMatchFilter';
export const NO_DRAFT_EMAIL = 'noDraftEmails';
export const NO_SCHEDULED_EMAIL = 'noScheduledEmails';
export const NO_SENT_EMAIL = 'noSentEmails';
export const NO_ARCHIVED_EMAIL = 'noArchivedEmails';
export const UNARCHIVED = 'unarchived';
export const APPROVALS = 'approvals';
export const ISSUES = 'issues';
export const EMAIL_STATUS = {
  ARCHIVED,
  DRAFT,
  SCHEDULED,
  SENT,
  UNARCHIVED,
  AI_GENERATED,
  ISSUES
};
export const NAMESPACE = 'MARKETING_EMAIL';
export const EMAIL_ALL_VIEW_ID = -48;
export const EMAIL_DRAFT_VIEW_ID = -49;
export const EMAIL_SCHEDULED_VIEW_ID = -50;
export const EMAIL_SENT_VIEW_ID = -51;
export const EMAIL_ARCHIVED_VIEW_ID = -52;
export const AI_GENERATED_VIEW_ID = -53;
export const ISSUES_VIEW_ID = -63;
export const FOLDER = 'folder';
export const LIST = 'list';
export const ARCHIVED_EMAILS = 'archived_emails';
export const DRAFT_EMAILS = 'draft_emails';
export const SCHEDULED_EMAILS = 'scheduled_emails';
export const SENT_EMAILS = 'sent_emails';

// Custom views are number based, hence the need for `\\d+`
export const MANAGE_URL_REG = new RegExp('^/email/[0-9]+/manage/state/(all|draft|scheduled|sent|archived|ai|issues|\\d+)?$');
export const FOLDER_URL_REG = new RegExp('^/email/\\d+/manage/folder(?:/\\d+)?');
export const AFFECTED_EMAILS_URL_REG = new RegExp('^/email/[0-9]+/manage/affected-emails$');