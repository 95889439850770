import { PROPERTIES } from 'email-management-lib/refactor/constants/manage/frameworkTable';
import { Metrics } from 'email-management-lib/Shared/utils/metrics';
import cloneDeep from 'hs-lodash/cloneDeep';
import http from 'hub-http/clients/apiClient';
import { userInfoSync } from 'hub-http/userInfo';
export function applySearchCRMRequestOptionsChanges({
  requestOptions,
  currentUserId
}) {
  var _requestOptions$filte;
  if (!(requestOptions !== null && requestOptions !== void 0 && (_requestOptions$filte = requestOptions.filterGroups) !== null && _requestOptions$filte !== void 0 && _requestOptions$filte.length)) {
    return requestOptions;
  }
  const options = cloneDeep(requestOptions);
  options.filterGroups.forEach(filterGroup => {
    filterGroup.filters.forEach(filter => {
      if ((filter.operator === 'IN' || filter.operator === 'NOT_IN') && filter.values.includes('__hs__ME') && [PROPERTIES.HS_CREATED_BY_USER_ID, PROPERTIES.UPDATED_BY, PROPERTIES.PUBLISH_AUTHOR].includes(filter.property)) {
        filter.values = filter.values.map(value => {
          return value === '__hs__ME' ? `${currentUserId}` : value;
        });
      }
    });
  });
  return options;
}
export function searchCrm(requestOptions) {
  const requestStart = performance.now();
  const currentUserId = userInfoSync().user.user_id;
  const options = applySearchCRMRequestOptionsChanges({
    requestOptions,
    currentUserId
  });
  return http.post('cosemail/v1/crm-search/search', {
    data: options
  }).then(response => {
    Metrics.timer('framework-crmSearch').update(performance.now() - requestStart);
    return response;
  });
}