import { buildQuickFetchClientInstance } from './buildQuickFetchClientInstance';
export { makeGroupsWithPropertiesQuickFetchKey, getGroupsWithPropertiesFetchUrl, makeFrameworkTypesQuickFetchKey, makeFrameworkTypesFetchUrl, makeTypeMetadataEntriesFetchKey, makeTypeMetadataEntryFetchKey, makeTypeMetadataEntriesFetchUrl, typeMetadataEntriesQuickFetchSmuggleKey, makePropertyMetadataEntriesFetchKey, makePropertyMetadataEntriesFetchUrl, makePipelinesFetchKey, makePipelinesFetchUrl } from './buildQuickFetchClientInstance';
export { withQuickLoad, enableEarlyCacheReturn, disableEarlyCacheReturn, isEarlyCacheReturnEnabled } from './quickLoad';
export { getFromIDB, setInIDB, clearIDB, __TEST_ONLY__DISABLE_IDB, __TEST_ONLY__REENABLE_IDB } from './persistence/indexeddb';
export { readSmuggledData } from './persistence/smuggleData';
let instance = null;
export const getFrameworkDataQuickFetchClient = () => {
  if (instance === null) {
    instance = buildQuickFetchClientInstance().catch(err => {
      throw err;
    });
  }
  return instance;
};