import { applySearchCRMRequestOptionsChanges } from 'email-management-lib/refactor/api/crmSearch';
import { CRM_SEARCH_REQUEST_DEFAULT_OPTIONS, DEFAULT_FILTERS, DESC, MARKETING_EMAIL_OBJECT_TYPE, PROPERTIES } from 'email-management-lib/refactor/constants/manage/frameworkTable';
import { AFFECTED_EMAILS_URL_REG, AI_GENERATED, ARCHIVED, DRAFT, FOLDER_URL_REG, ISSUES, MANAGE_URL_REG, SCHEDULED, SENT } from 'email-management-lib/refactor/constants/manage/shared';
import { EQ, HAS_PROPERTY, IN, LT_X_DAYS } from 'email-management-lib/refactor/utils/filters';
import { removeDuplicatedFilterOperators } from 'framework-listing-lib/utils/filters';
import quickFetch from 'quick-fetch';
import { HAS_CREATED_EMAILS_EARLY_REQUEST_ID } from './constants';
export const ISSUE_DAYS = 90;
export function getViewFromPathname(pathname) {
  const match = pathname.match(MANAGE_URL_REG);
  const view = (match || [])[1];
  return view;
}
export function getQueryFromSearch(search) {
  return new URLSearchParams(search).get('search');
}
export function isFolderPathname(pathname) {
  return FOLDER_URL_REG.test(pathname);
}
export function isAffectedEmailsPathname(pathname) {
  return AFFECTED_EMAILS_URL_REG.test(pathname);
}
export function getWindowPathname() {
  return window.location.pathname;
}
export function getWindowSearch() {
  return window.location.search;
}
export function getHasCreatedEmailsEarlyRequest() {
  return quickFetch.getRequestStateByName(HAS_CREATED_EMAILS_EARLY_REQUEST_ID);
}
export function getEarlyViewsRequest() {
  return quickFetch.getRequestStateByName(`crm-listing-pinned-views-${MARKETING_EMAIL_OBJECT_TYPE}-MARKETING_EMAIL-v4`);
}
export function getEarlyRequestedDefaultViewId() {
  const viewsRequest = getEarlyViewsRequest();
  // We default to the left-most view (first in array)
  return viewsRequest && viewsRequest.data && viewsRequest.data.results[0] &&
  // Type says it can only be a String, it can actually come back a Number
  String(viewsRequest.data.results[0].id) || undefined;
}
export function getSearchOptions({
  view,
  query,
  currentUserId
}) {
  if (typeof view === 'object') {
    const filterGroups = JSON.parse(view.filterGroups).map(filterGroup => ({
      // removeDuplicatedFilterOperators favors first elements over last
      filters: removeDuplicatedFilterOperators([...filterGroup.filters, ...(view.quickFilters ? JSON.parse(view.quickFilters) : []), ...DEFAULT_FILTERS])
    }));
    return applySearchCRMRequestOptionsChanges({
      requestOptions: Object.assign({}, CRM_SEARCH_REQUEST_DEFAULT_OPTIONS, {
        filterGroups,
        query: query || ''
      }),
      currentUserId
    });
  }
  let filters = [...DEFAULT_FILTERS, IN(PROPERTIES.ARCHIVED, ['false'])];
  if (view === DRAFT) {
    filters.push(IN(PROPERTIES.PUBLISH_STATUS, ['DRAFT']));
  } else if (view === SCHEDULED) {
    filters.push(IN(PROPERTIES.PUBLISH_STATUS, ['SCHEDULED']));
  } else if (view === SENT) {
    filters.push(IN(PROPERTIES.PUBLISH_STATUS, ['PUBLISHED']));
  } else if (view === AI_GENERATED) {
    filters.push(EQ(PROPERTIES.IS_AI_USED, 'true'));
  } else if (view === ARCHIVED) {
    filters = [...DEFAULT_FILTERS, IN(PROPERTIES.ARCHIVED, ['true'])];
  } else if (view === ISSUES) {
    filters = [...DEFAULT_FILTERS, HAS_PROPERTY(PROPERTIES.ERROR_TAGS), LT_X_DAYS(PROPERTIES.ERROR_DATE, ISSUE_DAYS, {
      upperBoundInclusive: true,
      lowerBoundInclusive: true,
      direction: 'BACKWARD'
    })];
  }
  const sorts = view === ISSUES ? [{
    order: DESC,
    property: PROPERTIES.ERROR_DATE
  }] : CRM_SEARCH_REQUEST_DEFAULT_OPTIONS.sorts;
  return Object.assign({}, CRM_SEARCH_REQUEST_DEFAULT_OPTIONS, {
    filterGroups: [{
      filters
    }],
    query: query || '',
    sorts
  });
}